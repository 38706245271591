import {
    $, addAction, INIT, LAYOUTEND,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $galleries = $('.image-gallery__slides');
    if (!$galleries.length) {
        return;
    }

    let initialized = false;

    function initializeGallery() {
        if (SetDesign.viewport.width() < 768 && !initialized) {
            initialized = true;
            $galleries.owlCarousel({
                items: 1,
                dots: false,
                loop: true,
                center: true,
                responsive: { 0: { items: 2 }, 480: { items: 3 } },
            });
        } else if (SetDesign.viewport.width() >= 768 && initialized) {
            initialized = false;
            $galleries.trigger('destroy.owl.carousel');
        }
    }

    function updateIndicator(event) {
        if (!initialized || event.item.count <= 0) {
            return;
        }

        let current = 0;
        if (event.property.name === 'position') {
            // eslint-disable-next-line no-underscore-dangle
            current = event.item.index - event.relatedTarget._clones.length / 2;
            current %= event.item.count;

            if (current < 0) {
                current = event.item.count + current;
            }
        }

        const { count } = event.item;
        const $galleryContainer = $(event.target).parents('.image-gallery');
        const $indicatorBar = $galleryContainer.find('.image-gallery__indicator-bar');
        const width = (1 / count) * 100;
        $indicatorBar.css({ width: `${width}%`, left: `${width * current}%` });
    }

    $galleries.on('changed.owl.carousel', updateIndicator);
    initializeGallery();
    addAction(LAYOUTEND, initializeGallery);
});

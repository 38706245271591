import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $row = $('.get-involved__row');
    if (!$row.length) {
        return;
    }

    const $h2s = $row.find('.heading');
    $h2s.wrap('<div class="get-involved__heading">');
    const $headings = $row.find('.get-involved__heading');
    addFilter('css-vars/register', (styles) => {
        const style = {
            'get-involved-heading-height': () => {
                $headings.css('min-height', 0);
                const maxHeight = Math.max(
                    ...$headings.map((index, el) => $(el).outerHeight()).get(),
                );
                $headings.css('min-height', '');
                return `${maxHeight}px`;
            },
        };
        return { ...styles, ...style };
    });
});

import {
    $, addAction, INIT, LAYOUTEND,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $highlight = $('.highlight').filter(
        (index, el) => el.className.indexOf('highlight--') >= 0,
    );
    $highlight.each((index, el) => {
        $(el).html((i, html) => {
            const words = html.split(' ');
            return `<span class="highlight__word">${words.join(
                ' </span><span class="highlight__word">',
            )}</span>`;
        });
    });

    function wrapLines() {
        $('.highlight__row .highlight__word').unwrap();
        $('.highlight--align').removeClass('highlight--align');
        const groups = SetDesign.groupByRow($highlight.find('.highlight__word'));
        groups.forEach(($els) => {
            $els.parents('.highlight').addClass('highlight--align');
            $els.wrapAll($('<span class="highlight__row">'));
        });
    }

    wrapLines();
    addAction(LAYOUTEND, wrapLines);
    $('.highlight').addClass('show');
});

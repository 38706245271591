import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $sliders = $('.grantee-slider');
    if (!$sliders.length) {
        return;
    }

    function initializeSlider() {
        $sliders.owlCarousel({
            // margin: 10,
            items: 1,
            dots: false,
            nav: true,
            // loop: true,
            // center: true,
            navText: [
                '<svg><use xlink:href="#slider-left"></use></svg>',
                '<svg><use xlink:href="#slider-right"></use></svg>',
            ],
            responsive: {
                576: { items: 2, slideBy: 2 },
                768: {
                    items: 3, slideBy: 3, loop: false, center: false,
                },
                992: {
                    items: 4, slideBy: 4, loop: false, center: false,
                },
            },
        });
    }

    $sliders.on('changed.owl.carousel', () => {
        doAction('refreshLazyLoad', $sliders);
    });

    initializeSlider();
    // addAction(LAYOUTEND, initializeSlider);
});

import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.donate-form').each((index, el) => {
        const $form = $(el);
        const $recurring = $form.find(
            '.donate-form__options--recurring .donate-form__option-input',
        );
        const $preset = $form.find('.donate-form__options--amount .donate-form__option-input');
        const $custom = $form.find('.donate-form__custom-input');
        const $submit = $form.find('.donate-form__submit');

        function updateURL() {
            const recurring = $recurring.filter(':checked').val();
            const total = $custom.val() || $preset.filter(':checked').val();
            $submit.attr(
                'href',
                $submit.data('href').replace('{{recurring}}', recurring).replace('{{total}}', total),
            );
        }

        $recurring.on('change', updateURL);
        $preset.on('change', (e) => {
            const val = parseFloat($(e.target).val());
            if (val > 0) {
                $custom.val('');
            }

            updateURL();
        });

        $custom.on('input change', () => {
            const val = parseFloat($custom.val());
            if (val > 0) {
                $preset.prop('checked', false);
            } else {
                $custom.val('');
            }

            updateURL();
        });

        updateURL();
        $submit.on('click', () => {
            $form.addClass('donate-form--submitted');
        });
    });
});

import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $body = $('body');
    $(document).on('click', 'a', (e) => {
        const $el = $(e.target);
        if ($el.attr('href') === '#couples-form') {
            $body.toggleClass('no-guide', $el.hasClass('js-couples-hide-guide'));
        }
    });
});

window.addEventListener('load', () => {
    window.cookieconsent.initialise({
        content: {
            message:
                'We and our partners use cookies on this site to improve our service, perform analytics, '
                + 'personalize advertising, measure advertising performance, and remember website preferences. '
                + 'By using the site, you consent to these cookies. '
                + 'For more information on cookies including how to manage your consent',
            dismiss: 'I Consent',
            link: 'visit our cookie policy',
            href: '/cookie-policy/',
        },
    });
});

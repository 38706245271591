import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.post-archive').each((index, el) => {
        const $this = $(el);
        const $button = $this.find('.post-archive__more');
        if (!$button.length) {
            return;
        }

        const pageLength = $this.data('pagination');
        $button.on('click', () => {
            const $posts = $this.find('.post-excerpt--hidden').slice(0, pageLength);
            $posts.removeClass('post-excerpt--hidden');

            if (!$this.find('.post-excerpt--hidden').length) {
                $button.remove();
            }
        });
    });
});
